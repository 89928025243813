html,
body {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

.pad-10{
  padding: 10%;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f179;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #F65A83;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #C21010;
  }
  
  body{
    scroll-behavior: smooth;
  }
  
.divCenter{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

  .navBar {
    position: fixed;
    top: 30px;
    right: 30px;
    z-index: 10;
  }
  
  .navBtn {
    padding: 10px;
    background: transparent;
    font-weight: bold;
    border: none;
    font-size: large;
    border-radius: 5px;
    color: black;
    cursor: pointer;
  }
  
  .mFont{
    font-family: 'Lato',sans-serif;
  }

  .navBtn:hover {
    background: #C21010;
    color: white;
  }
  
  .attachRight{
    position: fixed;
    top: 30%;
    right: 0px;
  }
  
  .attachRightBtn{
    background:#C21010;
    border:none;
    padding:10px;
    cursor: pointer;
  }
  
  /*Mobiles*/
  @media only screen and (max-width: 600px) {
    
    .pd-top-bottom{
      padding-top: 40%; 
      padding-bottom: 10%
    }
    .attachRight{
      display: none;
    }
    .attachRightCard{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-content: center;
    }
  }
  
  /*Laptops, Desktops etc*/
  @media only screen and (min-width: 600px) {
  
    .pd-top-bottom{
      padding-top: 10%; 
      padding-bottom: 20%
    }
    
    .attachRightCard{
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 30%;
      right: 0px;
    }
  
    .attachRight{
      display: block;
    }
  }