.workBtn {
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  background: #C21010;
  font-weight: bold;
  border: none;
  font-size: large;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.iconHome {
  width: 50%;
}

.HomeTxtHead {
  text-align: center;
  font-size: xx-large;
  font-weight: bold;
}

.backgroundTransparent {
  background: transparent;
}

.dialogBack{
  background: transparent;
  background-color: rgba(0, 0, 0, 0);
}

.readMoreBtn{
  padding: 8px;
  padding-left: 10px;
  padding-right: 10px;
  background: #d03d56;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.wp-block-social-links{
  display: none;
}

.why{
  text-align: justify;
  letter-spacing: 2;
  line-height: 35px;
}

.lineDiv{
  background:#F65A83;
  border-color:#F65A83;
  height:10px;
  width:50px;
  margin-bottom: 50px;
}